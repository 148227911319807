import { Settings } from '../../../config/Settings';
export class Api {
  HOST = Settings.KULTURIO_API_URL;
  
  get = async path => {
    return await fetch(this.HOST + path, { 
        headers:{ 
            "x-api-key" : Settings.KULTURIO_API_KEY,
            "Content-Type" : "application/json"
        }
    }).then(res => {
      return res.json();
    })
      .catch(err => {
        console.log("KiService error:", err);
      })
  }

}
