import { Api } from "./Api";

export class KiService extends Api {
    getAllScreens = async () => {
        return await this.get("/search?q=collectionId:12 AND documentType:display&status=publish");
    }

    getAllZones = async () => {
        let data = [];
        try {
            const _data = await this.get("/search?q=collectionId:12 AND documentType:zone&status=publish");
            data = _data.models;

        }catch (err) {
            console.error(err);
        }
        finally{
            return data;
        }
        
    }

    getZone = async (zoneId: string) => {
        let data = [];
        try {
            const _data = await this.get(`/search?q=collectionId:12 AND documentType:zone AND zone:${zoneId}&status=publish`);
            data = _data.models;
        }
        catch (err) {
            console.error(err);
        }
        finally{
            return data;
        }
    }


    getById = async (id: number) => {
        let data = [];
        try {
            const _data = await this.get(`/search?q=id:${id}`); //&status=publish
            data = _data.models;
        }
        catch (err) {
            console.error(err);
        }
        finally{
            return data;
        }
    }

    getByIds = async (id: number[]) => {
        let data = {};

        // creates a string formatted like "0 OR id:1 OR id:2 OR id:3"
        const idQuery = id.reduce((accStr, el, idx )=>{ return accStr + (!idx ? el.toString() : " OR id:"+el)}, "");
        try {
            const _data = await this.get(`/search?q=id:${idQuery}&status=publish`);
            data = _data.models;
        }
        catch (err) {
            console.error(err);
        }
        finally{
            return data;
        }
    }

    getByUuid = async (uuid: string) =>{
        let data = [];

        try {
            const _data = await this.get(`/documents/${uuid}`);
            data = _data;
        }
        catch (err){
            console.error(err);
        }
        finally{
            return data;
        }
    }



}