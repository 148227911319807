import React, { useReducer } from "react";
import {Settings} from "../../config/Settings";
//import { ReducerAction } from "react";
//import { Reducer } from "react";





const initialState = {
    constants: {
        siteUrl: Settings.PAGE_URL,
        largeFontRootSize: "20px",
        zoneUuids: [
            "abba4994-82bf-4f1d-aa50-4542835150d0",
            "52c50696-a89b-4b80-a8cb-ba787b23cb1d",
            "1163af1f-7f01-4ee7-b025-4bb336795977",
            "c87ee59b-c345-4ea9-9a5c-079f80fddee7",
            "8216e8fa-7a53-4b97-bf03-5717ade071ca",
            "2da56eac-38b3-4929-8a42-d3996b7aa52f",
            "82a4c844-2245-4953-a8c9-9b47e9cb81b3",
            "b9229777-454e-4302-b57d-2667ddff7354",
            "8e471480-21c0-4935-8090-dbff177bb0d1"]
    },
    data: {
        zones: [],
        settings: {},
        tabSettings: {},
        largeFont: false,
        outDatedBrowserWarning: true,
    },
    
}

export const Store: any = React.createContext(initialState);

interface valueType {
    state: State;
    dispatch: React.Dispatch<Action>;

}

interface State {
    constants: {
        siteUrl: string
        zoneUuids: string[]
    };
    data: {
        zones: object[]
        settings: object;
        tabSettings: object;
        largeFont: boolean;
        outDatedBrowserWarning: boolean;
    };

}

interface Action {
    type: "SET_DATA_ZONES" | "SET_DATA_SETTINGS" | "SET_DATA_TABSETTINGS" | "SET_DATA_LARGEFONT" | "SET_DATA_OUTDATEDBROWSERWARNING";
    payload: object[];
}


const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case "SET_DATA_ZONES":
            return { ...state, data: { ...state.data, zones: action.payload } };

        case "SET_DATA_SETTINGS":
            return { ...state, data: { ...state.data, settings: action.payload } };

        case "SET_DATA_TABSETTINGS":
            return { ...state, data: { ...state.data, tabSettings: action.payload } };

        case "SET_DATA_LARGEFONT":
            return { ...state, data: { ...state.data, largeFont: action.payload } };
        
        case "SET_DATA_OUTDATEDBROWSERWARNING":
            return { ...state, data: { ...state.data, outDatedBrowserWarning: action.payload } };
            
        default:
            return state;
    }
}

export const StoreProvider = props => {
    //@ts-ignore
    const [state, dispatch] = useReducer(reducer, initialState);

    const value: any = { state, dispatch };
    return (
        <Store.Provider value={value}>
            {props.children}
        </Store.Provider>)
}